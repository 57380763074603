import {Component, EventEmitter, Output} from '@angular/core';
import {BarcodeFormat} from '@zxing/library';

@Component({
    selector: 'app-scanner',
    templateUrl: './scanner.component.html',
    styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent {
    @Output('change') changeEmitter = new EventEmitter<string>();
    barcodeFormats: BarcodeFormat[] = [
        BarcodeFormat.CODE_128,
        BarcodeFormat.DATA_MATRIX,
        BarcodeFormat.EAN_13,
        BarcodeFormat.QR_CODE,
    ];

    scannerState = {
        cams: null,
        activeCam: null,
        isActive: false,
    };

    activateScanner() {
        this.scannerState.isActive = true;
    }

    onCamerasFound(cams) {
        this.scannerState.cams = cams;
        if (!this.scannerState.activeCam) {
            const rear = this.scannerState.cams.find(c => ['rear', 'back', 'hátsó'].some(x => c.label.toLowerCase().includes(x)));
            this.scannerState.activeCam = rear || this.scannerState.cams[this.scannerState.cams.length - 1];
        }
    }

    onBarcodeChange(val: string) {
        this.changeEmitter.emit(val);
        this.scannerState.isActive = false;
    }
}
