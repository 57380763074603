<div class="scanner-cnt active" *ngIf="scannerState.isActive">
    <zxing-scanner #scanner
                   class="coverVideo bg-dark"
                   [enable]="scannerState.isActive"
                   previewFitMode="cover"
                   [device]="scannerState.activeCam"
                   [formats]="barcodeFormats"
                   [tryHarder]="true"
                   (camerasFound)="onCamerasFound($event)"
                   (scanSuccess)="onBarcodeChange($event)">
    </zxing-scanner>
    <button type="button" class="btn btn-primary btn-circle-lg close-btn raised-2" (click)="scannerState.isActive = false">
        <fal i="times"></fal>
    </button>
</div>
