import {Component, Input, ViewChild} from '@angular/core';
import {AdvancedInputComponent} from '../advanced-input/advanced-input.component';
import {ScannerComponent} from '../../../@portal-shared/scanner/scanner.component';

@Component({
    selector: 'app-advanced-input-scanner',
    templateUrl: './advanced-input-scanner.component.html',
    styleUrls: ['./advanced-input-scanner.component.scss'],
})
export class AdvancedInputScannerComponent {
    @Input() inputTmp: AdvancedInputComponent;
    @ViewChild('scanner') scanner: ScannerComponent;

    activateScanner() {
        this.scanner?.activateScanner();
    }

    scannerValueChanged(value: string) {
        this.inputTmp.onNgModelChange(value);
    }
}
